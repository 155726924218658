import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import Head from '../components/head'

const NotFound = () => {
    return (
        <Layout>
            <Head title='404'/>
            <h2>This page does not exist (yet?).</h2>
            <p><Link to='/'>But you can head home</Link></p>
        </Layout>
    )
}

export default NotFound